<script>
const SIZE_CLASSES = {
  xs: 'max-h-24',
  sm: 'max-h-36',
  md: 'max-h-48',
  lg: 'max-h-60',
  xl: 'max-h-72',
  xxl: 'max-h-96',
}
const COLS_CLASSES = [
  '',
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
  'grid-cols-5',
  'grid-cols-6',
  'grid-cols-7',
  'grid-cols-8',
  'grid-cols-9',
  'grid-cols-10',
  'grid-cols-11',
  'grid-cols-12',
]
const COL_SPAN_CLASSES = [
  '',
  'col-span-1',
  'col-span-2',
  'col-span-3',
  'col-span-4',
  'col-span-5',
  'col-span-6',
  'col-span-7',
  'col-span-8',
  'col-span-9',
  'col-span-10',
  'col-span-11',
  'col-span-12',
]
</script>

<script setup>
import { defineModel, computed } from 'vue'
import LzIcon from './LzIcon'
import LoadingSpinner from './LoadingSpinner'

// Header definitions may be a label string or an object with attributes:
// * label - the header label
// * slug - (optional) sortable header event identifier for `header-clicked` (adds asc/desc arrow to button header)
// * children - (optional) array of sub-header labels/objects
//
// Only one level of subheaders is supported - children are not recursively checked for sub-children
const props = defineProps({
  headers: { type: Array, required: true }, // Array of header labels/objects (see above)
  columnSizes: { type: String, default: null }, // Column sizes definition for `grid-template-columns` value
  cellClass: { type: String, default: '' }, // Class (e.g.: padding) for header and body cells
  rows: { type: Array, default: () => [] },
  emptyRow: { type: String, default: '' },
  processing: { type: Boolean, default: false },
  sortedBy: { type: String, default: '' },
  descending: { type: Boolean, default: false },
  size: { type: String, default: 'xxl', validator: (val) => Object.keys(SIZE_CLASSES).includes(val) },
})
const headerRef = defineModel('headerRef')
const bodyRef = defineModel('bodyRef')
defineEmits(['headerClicked'])

const cols = props.headers.reduce((sum, value) => sum + (value.children?.length || 1), 0)
const bodyClass = SIZE_CLASSES[props.size]
const colsClass = props.columnSizes ? '' : COLS_CLASSES[cols]
const colsStyle = props.columnSizes ? `grid-template-columns: ${props.columnSizes}` : ''
const colSpanClass = COL_SPAN_CLASSES[cols]
const borderClass = 'border-primary-100 border'
const subgridStyle = 'grid-template-columns: subgrid' // Until Tailwind 3, which has grid-cols-subgrid

const headerCells = computed(() => {
  const row1 = []
  const row2 = []
  const hasSubheaders = !!props.headers.find((h) => !!h.children)
  props.headers.forEach((h) => {
    if (h.children) {
      row1.push({ slug: h.slug, label: h.label, classes: `${COL_SPAN_CLASSES[h.children.length]} flex justify-center` })
      row2.push(...h.children.map((c) => ({ slug: c.slug, label: c.label ? c.label : c, classes: '' })))
    } else {
      const label = h.label ? h.label : h
      row1.push({ slug: h.slug, label, classes: hasSubheaders ? 'row-span-2 flex items-center' : '' })
    }
  })
  return row1.concat(row2)
})
const arrowClass = (header) => {
  if (header.slug !== props.sortedBy) return 'opacity-10'
  return props.descending ? 'rotate-180' : ''
}
</script>

<template>
  <div class="border-base relative grid border text-base" :class="colsClass" :style="colsStyle">
    <div ref="headerRef" class="grid" :class="colSpanClass" :style="subgridStyle" data-role="header">
      <component
        :is="header.slug ? 'button' : 'div'"
        v-for="header in headerCells"
        :key="header.label"
        :type="header.slug ? 'button' : undefined"
        :aria-label="header.label"
        data-role="header-cell"
        class="bg-base sticky left-0 top-0 z-20 text-base font-bold"
        :class="[borderClass, cellClass, header.classes, { flex: header.slug }]"
        @click="$emit('headerClicked', header.slug)"
      >
        {{ header.label }}
        <LzIcon
          v-if="header.slug"
          path="icons/active-indicator"
          size="sm"
          class="scale-75 transform transition-all"
          :class="arrowClass(header)"
        />
      </component>
    </div>
    <div
      ref="bodyRef"
      class="bg-focus grid overflow-auto"
      :class="[bodyClass, colSpanClass]"
      :style="subgridStyle"
      data-role="body"
    >
      <div v-if="processing" class="py-4" :class="[colSpanClass, borderClass]" data-test="loading-row">
        <LoadingSpinner />
      </div>
      <div v-else-if="!rows.length && emptyRow" :class="[colSpanClass, borderClass, cellClass]" data-test="empty-row">
        {{ emptyRow }}
      </div>
      <template v-else v-for="(row, index) in rows" :key="index">
        <slot name="row" v-bind="{ row, index, cellClass, borderClass, colSpanClass, subgridStyle }" />
      </template>
    </div>
  </div>
</template>
